<template>
  <div class="list">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      offset="30"
    >
      <div v-for="item in newList" :key="item.id" :title="item" class="article-item">
        <router-link :to="{path:'Detail',query:{id:item.id}}">
          <div class="new-item" v-if="item.picture!=''">
            <div class="iteminfo">
              <h2 class="title">{{item.title}}</h2>
              <p class="info">
                <span class="posttime">{{ item.posttime }}</span>
              </p>
            </div>
            <div class="thumbpic">
              <van-image width="16rem" fit="fill" height="12rem" :src="item.picture"></van-image>
            </div>
          </div>
          <div class="new-item" v-else>
            <div class="iteminfo">
              <h2 class="title">{{decodeURIComponent(item.title.replace(/\+/g, "%20"))}}</h2>
              <p class="info">
                <span class="posttime">{{ item.posttime }}</span>
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </van-list>
  </div>
</template>
  <script>
export default {
  name: "ChannelNew",
  props: {
    id: String
  },
  data() {
    return {
      channelid: this.$props["id"],
      loading: false,
      finished: false,
      refreshing: false,
      lastdataaddtime: "",
      newList: []
    };
  },
  watch: {
    id(newValue) {
      this.channelid = newValue;
      this.newList = [];
      this.lastdataaddtime = "";
      this.onLoad();
    }
  },
  methods: {
    onLoad() {
      var that = this;
      var params = {
        top: 10,
        lastdataaddtime: that.lastdataaddtime,
        channelid: this.channelid
      };
      that.loading = true;
      that.$api.getChannelNew(params).then(res => {
        that.loading = false;
        if (res.data.length < params.top) {
          that.finished = true;
        }
        res.data.forEach(item => {
          that.newList.push(item);
          that.lastdataaddtime = item.addtime;
        });
      });
    }
  }
};
</script>
<style scoped>
.article-item {
  background: #fff;
  border-radius: 0.5rem;
  margin-bottom: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.article-item a .title {
  font-size: 2.4rem;
  color: #000;
  font-weight: normal;
  text-align: left;
  margin: 0;
  line-height: 4rem;
}
.article-item a .info {
  margin: 1rem 0 0 0;
  font-size: 2.4rem;
  color: #333;
  display: flex;
  align-items: center;
}
.posttime {
  font-size: 1.6rem;
  color: #666666;
}
.new-item {
  display: flex;
}
.iteminfo {
  margin-right: 1rem;
}
.new-item .thumbpic {
  height: 12rem;
  flex: 1;
}
</style>